@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";
@import "../../../styles/animations.module.scss";

.shop_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    &_topbanner {
        width: 100%;
        height: 50vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation: fadein 1s;
        }
    }

    &_title,
    &_subtitle {
        width: 100%;
        max-width: 1000px;
        margin: 0;

        @media (max-width: 900px) {
            width: 90%;
        }

    }

    &_subtitle {
        margin-top: 20px;
        font-size: $small_font;
        font-weight: 300;
        color: $primary;
    }

    &_title {
        color: $text;
        margin-bottom: 20px;
        font-size: $medium_font;
        font-weight: 300;
    }

    &_products {
        width: 100%;
        background-image: linear-gradient(90deg, white 0%, $primary_reverse 50%, white 100%);
        display: flex;
        justify-content: center;
        padding: 40px;
        box-sizing: border-box;

        @media (max-width: 900px) {
            padding: 20px 5px;
        }

        &_list {
            width: fit-content;
            display: grid;
            grid-template-columns: auto auto auto;
            column-gap: 40px;
            row-gap: 40px;

            @media (max-width: 900px) {
                grid-template-columns: auto;
                row-gap: 20px;
                column-gap: 10px;
            }
        }
    }
}