@import "../../../styles/fonts.module.scss";
@import "../../../styles/colors.module.scss";

.container{
    height: 80vh;
    max-width: 100%;
}
.title{
    color: $text;
    font-size: $large_font;
    font-weight: 300;
    max-width: 80%;
    text-align: center;
}

.text{
    max-width: 80%;
    margin: 0;
    font-size: $xsmall_font;
    color: $gray;
    text-align: center;
    margin-top: 10px;
}