@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";


.contact_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_top {

        aspect-ratio: 900/600;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        background-image: url("../../../images/low-poly-grid.svg");
        max-height: 700px;
        min-height: 500px;
        height: 60vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;



        h1 {
            font-size: $large_font;
            color: $primary;
            font-weight: 500;
        }

        &_icons {
            width: fit-content;
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 100px;

            @media (max-width:900px) {
                column-gap: 0;
                width: 100vw;
                grid-template-columns: auto;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &_bottom {
        width: 100%;
        max-width: 1000px;

        h1 {
            font-size: $large_font;
            color: $text;
            font-weight: 300;
        }

        ul {
            list-style: decimal;
            color: $text;
            font-size: $xsmall_font;

            li {
                h2 {
                    font-size: $small_font;
                    text-decoration: underline 2px $text;
                    font-weight: 400;
                }

                p {
                    font-size: $xsmall_font;
                    font-weight: 300;
                    color: $gray;

                    @media (max-width:900px) {
                        padding: 10px;
                        padding-top: 0;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}

.contact_card {
    padding: 20px;
    width: 200px;
    height: 170px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $primary;
    border-radius: 20px;

    @media (max-width:900px) {
        width: 50vw;
        height: 70%;
    }

    &_icon {
        width: 50px;
        height: 50px;
        color: $text;
    }

    &_text {
        text-align: center;
        font-size: $xsmall_font;
        font-weight: 300;
        color: $text;
    }
}