@import "../../styles/colors.module.scss";
@import "../../styles/fonts.module.scss";

footer {
    background-color: $text;
    flex-direction: column;
   
    p {
        color: $primary_reverse;
        font-weight: 300;
    }
}

.footer_list {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 20px 0px;

    li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2,
        a {
            margin: 0;
        }

        h2 {
            color: $primary_reverse;
            font-weight: 300;
            font-size: $xsmall_font;
        }

        a {
            font-weight: 300;
            color: $primary;
            font-size: $xxsmall_font;
            text-decoration: none;
        }

    }

    &_underline {
        display: block;
        background-image: linear-gradient(90deg, $primary_reverse 10%, $text 90%);
        width: 100%;
        height: 1px;
        margin-bottom: 10px;
    }

    &_social {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_icon {
            width: 35px;
            height: 35px;
            padding-top: 5px;
            padding-right: 10px;
            color: $primary;
        }
    }


}