@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";

.aboutus_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &_top {
        aspect-ratio: 900/600;
        width: 100%;
        height: 60vh;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        background-image: url("../../../images/low-poly-grid.svg");
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:900px) {
            height: 50vh;
        }

        h1 {
            font-size: $title_font;
            font-weight: 500;
            color: $primary_reverse;
        }
    }

    &_bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        @media (max-width:900px) {
            padding: 0 20px;
            box-sizing: border-box;
            margin-bottom: 50px;
        }

        &_section {
            margin: 50px 0px;
            width: 100%;
            max-width: 1200px;
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 50px;

            @media (max-width:900px) {
                grid-template-columns: auto auto;
                column-gap: 0;
                align-items: center;
                margin: 0;
            }

            &_text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                h1 {
                    font-size: $large_font;
                    font-weight: 300;
                    color: $text;

                    @media (max-width: 900px) {
                        text-align: center;
                        margin-bottom: 0;
                    }
                }

                p {
                    margin: 0;
                    color: $gray;
                    font-size: $xsmall_font;

                    @media (max-width: 900px) {
                        text-align: center;
                        font-weight: 300;
                    }
                }
            }

            img {
                border-radius: 12px;
                object-fit: cover;
                aspect-ratio: 1.5 / 1;
                width: 30vw;
                max-width: 500px;
                box-shadow: 20px 20px 0px 5px rgb(151, 142, 119);

                @media (max-width:900px) {
                    width: 40vw;
                    border-radius: 5px;
                    box-shadow: 5px 5px 0px 2px rgb(151, 142, 119);
                    margin-left: 5px;
                }

                &:nth-last-child(even) {
                    box-shadow: -20px 20px 0px 5px rgb(151, 142, 119);

                    @media (max-width:900px) {
                        margin-right: 5px;
                        box-shadow: -5px 5px 0px 2px rgb(151, 142, 119);

                    }
                }

            }


        }
    }

}