@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {

  --xxsmall_font: 0.9rem;
  --xsmall_font: 1.1rem;
  --small_font: 1.5rem;
  --medium_font: 2rem;
  --large_font: 3rem;
  --title_font: 5rem;

  @media (max-width: 900px) {
    --xxsmall_font: 0.9rem;
    --xsmall_font: 1.0rem;
    --small_font: 1.3rem;
    --medium_font: 1.5rem;
    --large_font: 1.6rem;
    --title_font: 3rem;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

html {

  /*  overscroll-behavior-y: none;*/
}