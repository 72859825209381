@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";
@import "../../../styles/animations.module.scss";


.home_container {
    width: 100%;
    height: fit-content;
    row-gap: 40px;

    &_title,
    &_subTitle {
        color: $text;
    }

    &_title {
        font-size: $title_font;
        font-weight: 300;
        margin: 0;

        @media (max-width: 900px) {
            color: $text;
            font-weight: 400;
        }
    }

    &_subTitle {
        font-size: $medium_font;
        font-weight: 300;
        width: 90%;

        @media (max-width: 900px) {
            //    font-weight: 500;
            color: $primary;
            //  filter: drop-shadow(0 0 0.4rem white);
        }
    }

    &_top {
        width: 80%;
        max-width: 1800px;
        display: grid;
        grid-template-columns: 50% 50%;
        height: 90vh;
        max-height: 1000px;
        position: relative;
        overflow: hidden;

        @media (max-width: 900px) {
            width: 100%;
            max-height: 400px;
            padding: 0 20px;
            box-sizing: border-box;
            grid-template-columns: 100%;
        }

        &_left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @media (max-width: 900px) {
                padding-left: 10px;
            }

            a {
                padding: 12px 40px;
                border: none;
                border-radius: 50px;
                font-size: $xsmall_font;
                background-color: $text;
                color: $primary;
                transition: 0.2s;
                text-decoration: none;

                &:hover {
                    background-color: $primary;
                    color: $text;
                    padding: 12px 70px;

                    @media (max-width: 900px) {
                        padding: 12px 55px;
                    }
                }
            }
        }


        img {
            object-fit: cover;
            width: 100%;
            aspect-ratio: 1/ 1.3;
            animation: fadein 1s;

            @media (max-width: 900px) {
                position: absolute;
                z-index: -1;
                width: 70%;
                right: 0;
                width: 100%;
            }
        }
    }



    &_bottom {

        display: flex;
        flex-direction: column;
        align-items: center;
        aspect-ratio: 900/600;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        background-image: url("../../../images/layered-waves-background.svg");

        &_title {
            font-size: $large_font;
            font-weight: 300;
            color: $text;
            margin-top: 40px;
            margin-bottom: 10px;
        }

        &_about {
            width: 100%;
            max-width: 1200px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            column-gap: 50px;

            @media (max-width: 900px) {
                padding: 0 10px;
                box-sizing: border-box;
                display: grid;
                grid-template-columns: auto auto;
                row-gap: 20px;
                column-gap: 20px;
            }
        }

        &_split {
            display: grid;
            grid-template-columns: 50% 50%;
            align-items: center;
            row-gap: 50px;
            margin-top: 20vh;
            padding-bottom: 30px;

            @media (max-width: 900px) {
                display: flex;
                flex-direction: column-reverse;
                padding-bottom: 20px;
            }

            &_right {

                @media (max-width: 900px) {
                    padding: 0 20px;
                }

                h2 {
                    color: $primary;

                    @media (max-width: 900px) {
                        filter: invert(0.7);
                        mix-blend-mode: difference;
                    }
                }
            }
        }
    }
}

.about_card {
    width: 100%;
    aspect-ratio: 1.5 / 1;
    background: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;

    @media (max-width: 900px) {}

    &:hover {
        scale: 1.05;
        background-color: white;
        box-shadow: 0 0 20px 1px rgba(128, 128, 128, 0.434);
    }

    &_icon {
        width: 50px;
        height: 50px;
        color: $primary;
    }

    h1 {
        font-size: $medium_font;
        font-weight: 200;
        color: $text;
        margin: 0;
    }

    h2 {
        font-size: $xsmall_font;
        font-weight: 200;
        color: $text;
    }
}