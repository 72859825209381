@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";

.cart_container {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    max-width: 1500px;
    position: relative;
    height: 90vh;


    &_items {
        width: 100%;
        display: flex;
        flex-direction: column;

        &_topbar {
            width: 100%;
            display: flex;
            flex-direction: row;

            h1 {
                font-weight: 300;
                width: 100%;
                text-align: center;
                color: $primary;
            }
        }

        &_list {
            height: 80vh;
            width: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            // remove scrollbar
            -ms-overflow-style: none;
            scrollbar-width: none;

            h2 {
                font-size: $small_font;
                color: $text;
                font-weight: 300;
                text-align: center;
                width: 100%;
                margin-top: 10vh;

                a {
                    color: $primary;
                    text-decoration: underline 2px solid $primary;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }



    &_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 15vh;

        &_amount {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 40px;

            h2,
            h1 {
                margin: 0;
            }

            h2 {
                font-weight: 300;
                color: $gray;
                font-size: $xsmall_font;
            }

            h1 {
                font-weight: 500;
                color: $text;
                font-size: $small_font;
            }
        }

        p {
            color: $gray;

            a {
                color: $text;
            }
        }

        &_terms {
            width: 100%;
            display: flex;
            margin: 5vh 0;

            input {
                accent-color: $primary;
            }


        }

        button {
            width: fit-content;
            height: fit-content;
            padding: 10px 50px;
            border: none;
            border-radius: 12px;
            font-weight: 500;
            font-size: $xsmall_font;
            background-color: $primary;
            color: $text;
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }
}

.fade_overlay {
    width: 33%;
    min-width: calc(100% - 1500px);
    height: 100%;
    position: absolute;
    right: 0;
    display: block;
    background-image: linear-gradient(to bottom, white 0%, white 10%, $primary 100%);
    z-index: -1;

}

.cart_item {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;

    li {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 170px;
        height: fit-content;

        h2 {
            font-size: $small_font;
            font-weight: 300;
            text-align: center;
            margin: 0;
        }
    }

    &_qty {


        display: flex;
        align-items: center;
        justify-content: center;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 7px;
        }

        p {
            width: fit-content;
            padding: 0 10px;
            text-align: center;
            margin: 0;
        }
    }

    &_product {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        position: relative;

        img {
            border-radius: 12px;
            object-fit: cover;
            width: 50%;
            background-color: $primary_reverse;
            aspect-ratio: 1 / 1;
        }

        &_cross {
            position: absolute;
            top: 0px;
            right: calc(25% - 12.5px);
            color: $text;
            width: 25px;
            height: 25px;
            transition: 0.2s;

            &:hover {
                color: $primary;
            }
        }

        p,
        h1 {
            text-align: left;
            width: 50%;
            margin: 0 2px;
        }

        h1 {
            font-size: $xsmall_font;
            font-weight: 300;
        }

        p {
            font-size: $xxsmall_font;
            font-weight: 300;
        }
    }

}


.mobile_cart_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 75vh;

    &_list {
        width: 100%;
        height: 90vh;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;

        h1 {
            font-size: $small_font;
            color: $text;
            font-weight: 300;
            text-align: center;
            width: 100%;
            margin-top: 10vh;

            a {
                color: $primary;
                text-decoration: underline 2px solid $primary;
            }
        }
    }

    &_checkout {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //background: linear-gradient(to bottom, $primary 0%,white 40%);
        border-top: 2px solid $primary;

        &_total {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h5 {
                font-size: $medium_font;
                font-weight: 300;
                color: $text;
                margin: 0;
                margin-top: 10px;

                &:nth-child(odd) {
                    color: $gray;
                }
            }
        }

        p {
            font-size: $xsmall_font;
            color: $gray;
        }

        &_button {
            width: 70%;
            height: 50px;
            margin: 20px 0;
        }

        &_agree {
            display: flex;
            align-items: center;

            input {
                width: 20px;
                height: 20px;
                accent-color: $primary;
            }

            label {
                margin: 5px;
                font-size: $xsmall_font;
                color: $text;

                a {
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }
    }

}


.mobile_cart_item {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    height: 30vw;
    padding: 10px;
    box-sizing: border-box;

    &_img {
        height: fit-content;
        width: 100%;
        position: relative;

        &_icon {
            position: absolute;
            right: -12.5px;
            top: -10px;
            width: 25px;
            height: 25px;

            &:hover {
                color: $primary;
            }
        }

        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 12px;
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;
        height: fit-content;
        margin: 0;

        li {
            height: fit-content;

            h1 {
                margin: 5px 0;
                text-align: left;
            }

            h2 {
                margin: 0;
                font-size: $xsmall_font;
                font-weight: 300;
            }
        }


        &_split {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            h3 {
                margin: 0;
                font-size: $small_font;
                font-weight: 400;
            }


        }
    }
}