@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";
@import "../../../styles/animations.module.scss";

.product_container {
    width: 70vw;
    max-width: 1000px;
    height: fit-content;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
    margin-top: 10vh;
    margin-bottom: 5vh;
    animation: fadein 1s;

    @media (max-width: 900px) {
        width: 100%;
        grid-template-columns: 100%;
        margin-top: 0;
    }

    &_img {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_big_img {
            width: 100%;
            height: fit-content;
            position: relative;
            border-radius: 20px;

            @media (max-width: 900px) {
                width: 100vw;
                height: 100vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: cover;

                @media (max-width: 900px) {
                    position: absolute;
                    border-radius: 0;
                    transition: 0;
                    width: auto;
                    max-width: 100vw;

                }

            }

            &_overlay {
                left: 0;
                position: absolute;
                opacity: 0;
            }
        }

        &_small_imgs {
            width: 100%;
            padding-top: 20px;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: calc(25% - 22px) calc(25% - 22px) calc(25% - 22px) calc(25% - 22px);
            column-gap: 30px;

            @media (max-width: 900px) {
                display: none;
            }

            img {
                width: 100%;
                aspect-ratio: 1 / 1;
                border-radius: 7px;
                object-fit: cover;

            }
        }
    }


    &_description_container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 5% 23% 4% 30% 20% 20%;

        @media (max-width: 900px) {
            padding: 30px;
            padding-top: 10px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
        }

        h1,
        h2 {
            color: $text;
            margin: 10px 0;
        }

        h6,
        p,
        h3 {
            margin: 10px 0;
            color: $gray;
        }

        h6 {
            font-weight: 500;
            font-size: $xsmall_font;
        }

        h1 {
            font-size: $large_font;
        }

        p {
            font-size: $xsmall_font;
        }

        &_price {
            display: grid;
            grid-template-columns: auto auto;
            width: fit-content;
            column-gap: 10px;
            align-items: center;
            height: fit-content;


            @media (max-width: 900px) {
                grid-template-columns: 20% auto auto;
                width: 100%;
            }

            h3 {
                font-size: $xsmall_font;
                margin: 0;
                font-weight: 600;
                text-decoration: line-through 2px solid;

                @media (max-width: 900px) {
                    justify-self: flex-end;
                }
            }
        }

        &_purchase {
            width: 100%;
            display: flex;
            flex-direction: row;
            height: 50px;

            @media (max-width: 900px) {
                justify-content: space-between;
            }

            &_amount {

                display: grid;
                grid-template-columns: 30% 30% 30%;
                width: 150px;

                button,
                input {
                    width: 100%;
                    padding: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    border: none;
                    background-color: $primary_reverse;
                    color: $text;
                }

                input {
                    font-size: $xsmall_font;
                    outline: none;
                }

                button:active {
                    background-color: $primary;
                }

                button:first-child {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }

                button:last-child {
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }

            &_buy {
                width: 100%;
                max-width: 200px;
                border: none;
            }
        }
    }
}

.img_picker_left,
.img_picker_right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    padding: 10px;
    height: fit-content;
    width: fit-content;
    border-radius: 50px;
    box-shadow: 0 0 10px 0 rgba(161, 161, 161, 0.28);
    z-index: 10;

    &_icon {
        width: 20px;
        height: 15px;
        color: $gray;
    }
}

.img_picker_left {
    left: 10px;
}

.img_picker_right {
    right: 10px;
}


.recommended_container {
    width: 100vw;
    height: fit-content;
    background-image: linear-gradient(90deg, white 0%, $primary_reverse 50%, white 100%);
    margin-bottom: 20px;
    overflow-x: scroll;

    &_list {
        min-width: 100%;
        width: max-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px 0;
        column-gap: 20px;

        @media (max-width:900px) {
            justify-content: flex-start;
            margin-left: 20px;
        }
    }
}