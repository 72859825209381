@import "../../styles/colors.module.scss";
@import "../../styles/fonts.module.scss";
@import "../../styles/animations.module.scss";

a {
    text-decoration: none;
}

.product_card {
    width: 300px;
    max-height: 400px;

    aspect-ratio: 1 / 1.3;
    //height: fit-content;
    background-color: white;
    //box-shadow: -3px 3px 10px 1px lightgray;
    border-radius: 20px;

    display: grid;
    align-items: flex-start;
    grid-template-rows: 49% 6% 18% 4% 9% 14%;
    padding: 15px;
    box-sizing: border-box;
    transition: 0.2s;

    @media (max-width: 900px) {
        grid-template-rows: 49% 13% 14% 4% 8% 12%;
        aspect-ratio: 1 / 1.4;
        width: 100%;
        max-width: 300px;
        padding: 10px;
    }

    &:hover {
        scale: 1.1;
    }

    &_img {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            background-color: $primary_reverse;
            border-radius: 7px;
            object-fit: cover;
            width: 100%;
            height: 100%;

        }
    }

    &_title {

        height: 100%;
        display: flex;
        align-items: center;

        &_text {
            font-size: $xsmall_font;
            font-weight: 500;
            margin: 0;
            color: $text;

            @media (max-width: 900px) {
                font-weight: 300;
            }
        }
    }

    p {
        font-size: $xxsmall_font;
        margin: 0;
        margin-top: 5px;
        color: $gray;

        overflow: hidden;
        height: calc($xxsmall_font * 3);

        @media (max-width: 900px) {
            height: calc($xxsmall_font * 3);
            font-weight: 300;
        }

    }

    &_price {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        width: 100%;

        h3,
        h2 {
            margin: 0;

        }

        h3 {
            color: $gray;
            font-weight: 300;
            font-size: $xsmall_font;
        }

        h2 {
            color: $text;
            text-align: right;
            font-weight: 400;
            font-size: $small_font;
        }
    }
}