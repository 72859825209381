@import "../../styles/fonts.module.scss";
@import "../../styles/colors.module.scss";

.button_cart_effect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 40px;
    width: 100%;
    height: 100%;
    background-color: $primary;
    color: $text;
    font-size: $xsmall_font;
    transition: 0.3s;
    z-index: 10;
    position: relative;
    overflow: hidden;

    &:hover {
        color: $primary;
        background-color: $text;
    }

    &_icon {
        left: 10%;
        top: 25%;
        width: 20px;
        height: 20px;
        position: absolute;
    }
}