@import "./fonts.module.scss";
@import "./colors.module.scss";

.round {
    border-radius: 12px;
}

.center_children,
.max_vert_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.max_size,
.max_vert_center {
    position: relative;
    width: 100%;
    height: 100%;
}

.underline_text {
    font-size: $xsmall_font;
    padding: 5px 10px;
    background-color: $text;
    color: $primary;
    width: fit-content;
    border-radius: 12px;
}

button {
    cursor: pointer;
}

.vertical_list,
.max_vert_center {
    flex-direction: column;
}

.section_title {
    width: 100%;
    max-width: 1200px;
    text-align: left;
    font-weight: 300;
    font-size: $large_font;
    margin: 10px;
}