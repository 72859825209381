@import "../../styles/colors.module.scss";
@import "../../styles/fonts.module.scss";

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //background-color: $primary;


    &_right,
    &_left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;


        li {
            padding: 0 20px;

            @media(max-width: 900px) {
                padding: 0 10px;
            }

            a {
                text-decoration: none;
                position: relative;
                width: 100%;
                height: 100%;

                h3:hover,
                h1:hover {
                    color: $primary;
                }

                h3,
                h1,
                h4 {
                    color: $text;
                    transition: 0.1s;
                }

                h3 {
                    font-weight: 300;
                    @media(max-width: 900px) {
                        font-size: $xsmall_font;
                    }
                }

                h1 {
                    font-weight: 300;

                    @media(max-width: 900px) {
                        font-size: $small_font;
                    }
                }

                h4 {
                    position: absolute;
                    top: 12px;
                    right: -20px;
                    margin: 0;
                    background-color: rgba(162, 146, 56, 0.218);
                    border-radius: 50px;
                    box-sizing: border-box;
                    font-size: $xsmall_font;
                    width: fit-content;
                    padding: 10px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 300;
                    z-index: 3;
                }
            }
        }
    }

    &_left {
        padding-left: 10%;

        @media(max-width: 900px) {
            padding: 0;
        }
    }

    &_right {
        padding-right: 10%;

        @media(max-width: 900px) {
            padding-right: 5%;
            ;
        }

        &_icon {
            color: $text;
            width: 20px;
            height: 20px;
            transition: 0.1s;

            &:hover {
                color: $primary;
            }
        }
    }
}

.underline {
    width: 100%;
    height: 3px;
    display: block;
    background-image: linear-gradient(90deg, white 0%, $primary 50%, white 100%);

}