@import "../../styles/colors.module.scss";
@import "../../styles/fonts.module.scss";

.rating_container {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    &_icon {
        width: 15px;
        height: 15px;
        color: $primary;
    }

    p{
        font-size: $xxsmall_font;
        margin: 0;
        margin-left: 10px;
        color: $text;
    }
}