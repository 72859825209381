@import "../../../styles/colors.module.scss";
@import "../../../styles/fonts.module.scss";

.notFound_img {

    width: 50vw;
    max-width: 500px;

    aspect-ratio: 1.5 / 1;
    border-radius: 20px;
    object-fit: cover;
}

.notFound_text {
    font-size: $medium_font;
    color: $text;
    font-weight: 300;
}